import React from "react"
import { PageProps, Link } from "gatsby"
import { Row, Col, Container, Card } from "react-bootstrap"
import {
  faCogs,
  faDollarSign,
  faUserShield,
  faClipboardCheck,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/FullScreenLayout"
import SEO from "../../components/seo"
import ContactForm from "../../components/ContactForm"
import SalesPoint from "../../components/SalesPoint"
import Header from "../../components/Header"

import { Heading, Lead } from "./style.module.scss";

import ReviewSplashBg from "./images/review-bg.jpg"

const FreeReview = (props: PageProps) => (
  <Layout>
    <SEO
      title="Free architecture review"
      description=" We'll evaluate your cloud software and deployment practices against the AWS Well-Architected framework for free, and provide some ideas on how to improve."
    />
    <Header logoColor={"dark"} />
    <div
      style={{
        position: "relative",
        height: "40vh",
        backgroundImage: `url(${ReviewSplashBg})`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: "rgba(104, 65, 186,.85)",
          textAlign: "center",
          color: "var(--white)",
        }}
      >
        <Container className="d-flex flex-column align-items-center">
          <h1 className={Heading} style={{ textTransform: "uppercase" }}>
            Free "well architected" review
          </h1>
          <p className={Lead} className="mb-0 w-75">
            We'll evaluate your cloud software and deployment practices against
            the AWS Well-Architected framework and provide some ideas on how to
            improve.
          </p>
        </Container>
      </div>
    </div>
    <Container className="mt-4 mb-5">
      <Row>
        <Col sm={12} md={12} lg={10} className={"offset-0 offset-md-1"}>
          <Card className="shadow-sm">
            <Card.Body className="pl-5 pr-5">
              <h2 className="mt-2 mb-5 text-center">
                We'll discuss five main topics:
              </h2>
              <SalesPoint icon={faCogs} title="Operations">
                Modern software systems require teams that know how to run as
                well as develop them. How do you run and monitor your systems?
                Where are the weak points?
              </SalesPoint>

              <SalesPoint icon={faUserShield} title="Security">
                Recent high-profile data breaches have focused minds around
                security and data protection. How well does your organisation
                protect information and systems?
              </SalesPoint>

              <SalesPoint icon={faClipboardCheck} title="Reliability">
                Customers expect round the clock access and high performance.
                Outages aren't acceptable. How well do you prevent failure? How
                quickly can you recover?
              </SalesPoint>

              <SalesPoint icon={faChartBar} title="Efficiency">
                Most software organisations suffer from poor resource
                utilisation and therefore, poor value for money. How efficient
                is your system? Are your resources underutilised? Could you
                benefit from going{" "}
                <Link to="/services/serverless">Serverless?</Link>
              </SalesPoint>

              <SalesPoint icon={faDollarSign} title="Cost Optimisation">
                In these uncertain times, you need to be sure you're getting
                value for money. Are you getting the best bang for your buck?
              </SalesPoint>
            </Card.Body>
          </Card>

          <hr className="mt-4 mb-4" style={{ borderTopStyle: "dashed" }} />

          <ContactForm
            title="Schedule your architecture review"
            lead="Let us know your email and we will be in contact shortly to schedule your free review:"
            hideSubject={true}
            hideMessage={true}
            subjectValue={"Free architecture review"}
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default FreeReview
