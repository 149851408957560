/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import RootLayout from "../RootLayout"

const FullScreenLayout = ({ children }) => {
  return <RootLayout>{children}</RootLayout>
}

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullScreenLayout
